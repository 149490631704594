@import "variables";

%before-element {
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: -1px;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: $main-color;
    opacity: 1;
    transition: opacity 0.2s, width 0.1s;
  }
}

%table-hover {
  position: relative;

  @extend %before-element;

  &:before {
    width: 0;
    opacity: 0;
  }

  &:hover,
  &:active {
    cursor: pointer;
    border-color: $separator;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.07);

    &::before {
      width: 4px;
      opacity: 1;
    }
  }
}

@function height-vh($value) {
  @return calc(#{$value} * var(--height-point));
}

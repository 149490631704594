.filter {
  &__category {
    display: flex;
    flex-flow: column wrap;
    margin: 0;
    padding-left: 0;

    &--is-opened {
      margin-bottom: 10px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  &__category-label {
    display: flex;
    align-items: center;
    font-family: $main-font;
    font-weight: 600;
    font-size: 13px;
    color: $text-color;
    padding-left: 30px;
    padding-right: 20px;
    margin-bottom: 10px;
    letter-spacing: 0;
    line-height: 32px;
    min-height: 40px;
    border-radius: 0 5px 5px 0;
    user-select: none;
    cursor: pointer;

    span {
      display: flex;
    }

    @media (max-width: 767px) {
      border-radius: 0;
    }

    &--is-opened {
      background: $main-color;
      color: #ffffff;
    }
  }

  &__category-arrow {
    transform: rotate(-90deg);
    margin-left: auto;

    path {
      fill: $text-color;
    }

    &--is-opened {
      transform: none;

      path {
        fill: #fff;
      }
    }
    &--is-opened-no-rotation {
      path {
        fill: #fff;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 30px;
    padding-left: 40px;
    padding-right: 15px;
    cursor: pointer;

    &--is-radio {
      display: flex;
    }

    &--is-selected {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 0 20px 20px 0;
        background: $radio-color;
        z-index: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background: $main-color;
        z-index: 1;
      }
    }

    &--is-capitalized {
      text-transform: capitalize;
    }
  }

  &__item-title {
    font-family: $main-font;
    font-weight: 400;
    font-size: 13px;
    color: $text-color;
    letter-spacing: 0;
    line-height: 30px;
    padding-right: 10px;
    position: relative;
    z-index: 2;

    &--is-selected {
      font-weight: 500;
    }
  }

  &__item-checkbox {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid $radio-color;
    box-sizing: border-box;
    background: #ffffff;
    position: relative;
    margin-left: auto;
    flex-shrink: 0;
    z-index: 2;

    &--is-selected {
      border-color: $main-color;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: $main-color;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

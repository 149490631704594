.site-menu {
  height: 50px;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: $body-color;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    content: '';
    background: $radio-color;
    z-index: 1;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    list-style: none;
    display: flex;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
  }

  &__item-link {
    height: calc(100% - 3px);
    display: flex;
    position: relative;
    color: $main-color;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    transition: 300ms all ease-in-out;
    align-items: center;
    font-weight: 500;

    &:hover {
      background: $radio-color;
    }

    &:after {
      transition: 300ms all ease-in-out;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 3px;
      z-index: 3;
      background: $radio-color;
    }

    &--is-active {
      background: $radio-color;

      &:after {
        background: $main-color;
      }
    }
  }
}

@import '../variables';
@import '../mixins';
@import 'components';
@import 'cell';
@import 'head-cell';

.detailed-table {
  display: flex;
  flex-flow: column wrap;
  padding-left: 0;
  padding-top: 4px;
  margin-top: 0;

  @media (max-width: 767px) {
    padding-top: 9px;
  }

  * {
    box-sizing: border-box;
  }

  .row {
    display: flex;
    box-sizing: border-box;
    min-height: 60px;

    &:not(:last-child) {
      margin-bottom: 5px;

      @media (max-width: 767px) {
        margin-bottom: 2px;
      }
    }

    &__primary {
      @extend %table-hover;

      display: flex;
      width: 100%;
      border: 1px solid $border-grey;
      background: #fff;
      position: relative;

      svg {
        width: 8px;
        height: 5px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        display: none;
      }

      path {
        fill: $text-color;
      }

      @media (max-width: 767px) {
        &:hover,
        &:active {
          box-shadow: none;
        }

        svg {
          display: block;
        }
      }

      &--is-opened {
        @extend %before-element;

        @media (max-width: 767px) {
          &:before {
            width: 4px;
            opacity: 1;
          }
        }

        svg {
          transform: translateY(-50%);
        }
      }
    }

    &__secondary {
      display: none;

      &--is-opened {
        display: flex;
        flex-flow: column wrap;
        margin-top: 11px;
        margin-bottom: 20px;

        @media (min-width: 768px) {
          display: none;
        }
      }
    }

    @media (max-width: 767px) {
      min-height: 40px;
      flex-flow: column;
    }
  }

  .secondary-line {
    display: flex;
    align-items: center;
    min-height: 24px;
    padding-left: 30px;
    margin-bottom: 2px;

    @media (min-width: 768px) {
      padding-left: 105px;
    }

    &:nth-of-type(2n + 1) {
      background: $border-grey;
    }

    &:last-of-type {
      margin-bottom: 10px;
    }

    .cell--is-total {
      justify-content: flex-end;
    }
  }

  .head-row {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 9px;

    &--up-to-1200 {
      .head-cell--is-total {
        @media (max-width: 1199px) {
          padding-right: 25px;
        }
      }
    }
  }
}

@import 'row-up-to-1200';
@import 'row-up-to-1440';

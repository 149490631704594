.top-report {
  height: 100vh;
  background-image: url('../images/top_table_bg.jpg');
  background-size: cover;
  overflow: hidden;

  .detailed-table {
    //2.6px at 1200px screen and 2.3px at 900px screen
    --scale: 1;
    overflow-y: auto;

    flex-flow: column nowrap;
    height: 100%;
    margin: 0;
    padding: calc(var(--scale) * 14px);

    @media (max-width: 1024px) {
      padding: 12px;
    }

    &.cycle_three_items {
      --scale: 3 !important;
      padding: 15vh calc(var(--scale) * 14px);
      justify-content: center;
      .row {
        max-height: 20vh;
        &:first-child {
          display: none;
        }
      }
      .cell {
        &__shooter-name {
          margin-bottom: 6px;
        }
      }
    }

    .row {
      flex-grow: 1;
      &:first-child {
        .row__primary {
          background: #001f3e;
          border: none;
        }
      }
      &:not(:first-child):nth-child(even) {
        .row__primary {
          background: #004a93;
        }
        .cell--is-total-star {
          background: #e2383f;
        }
      }
      &:not(:first-child):nth-child(odd) {
        .row__primary {
          background: #013b76;
        }
        .cell--is-total-star {
          background: #b52d31;
        }
      }
      &__primary {
        border: none;
        &:hover:before {
          display: none;
        }

        @media (max-width: 1024px) {
          height: 100%;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0; //calc(var(--scale) * 1px);;
      }
    }

    .score-block {
      line-height: 1;
      font-size: calc(var(--scale) * 20px);
      color: #fff;

      @media (max-width: 1024px) {
        font-size: calc(var(--scale) * 12px);
      }
      @media (max-width: 480px) {
        font-size: calc(var(--scale) * 8px);
      }
    }

    .star-block {
      font-size: calc(var(--scale) * 14px);
      width: 100px;
      color: #fff;
      background: none;
      border-radius: calc(var(--scale) * 4px) 0 0 calc(var(--scale) * 4px);

      @media (max-width: 1024px) {
        font-size: calc(var(--scale) * 10px) !important;
        color: #fff !important;
        width: auto;
        padding-right: 10px;
      }

      &:before {
        font-size: calc(var(--scale) * 8px);
      }

      &:after {
        display: none;
      }
    }

    .shooter_img {
      width: calc(var(--scale) * 60px);
      padding: calc(var(--scale) * 10px) 0;
      flex-shrink: 0;
      img {
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    // calc(var(--scale) * 14px)

    .cell {
      padding: 0 calc(var(--scale) * 10px);

      @media (max-width: 1024px) {
        padding: 0 calc(var(--scale) * 6px);
      }

      &__shooter-texts {
        flex-grow: 1;
      }

      &__shooter-name {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }

      &__club-name {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }

      &--is-number {
        width: calc(var(--scale) * 40px);
        padding: 0 calc(var(--scale) * 4px);

        @media (max-width: 1024px) {
          width: calc(var(--scale) * 28px);
          padding: 0 calc(var(--scale) * 2px);
        }

        &:after {
          display: none;
        }

        span {
          background: #fff;
          color: #000;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(var(--scale) * 20px);
          height: calc(var(--scale) * 20px);

          line-height: 1;
          font-size: calc(var(--scale) * 14px);

          @media (max-width: 1024px) {
            font-size: calc(var(--scale) * 10px);
            width: calc(var(--scale) * 14px);
            height: calc(var(--scale) * 14px);
          }
        }
      }

      &--is-total-star,
      &--is-star {
        padding: 0;
      }

      &--is-total {
        padding-right: calc(var(--scale) * 10px);
        @media (max-width: 1024px) {
          padding-right: calc(var(--scale) * 4px);
        }
        @media (max-width: 480px) {
          min-width: auto;
        }
      }

      &--is-total-star {
        background: #dc2f34;
        @media (max-width: 1024px) {
          flex-direction: row;
        }
      }

      &--is-shooter {
        &::after {
          display: none;
        }
        &.heading {
          .cell__shooter-name {
            justify-content: center;
          }
        }
        .cell {
          &__shooter-name {
            line-height: 1;
            font-size: calc(var(--scale) * 15px);
            transform: none;
            color: #fff;

            @media (max-width: 1024px) {
              font-size: calc(var(--scale) * 10px);
            }
            @media (max-width: 480px) {
              font-size: calc(var(--scale) * 8px);
            }
          }
          &__shooter-logo {
            height: calc(var(--scale) * 20px);

            + .cell__shooter-name {
              padding: 0 10px;
              margin: 0 auto;
            }
            @media (max-width: 1024px) {
              margin: 0 8px;
            }
            @media (max-width: 480px) {
              height: calc(var(--scale) * 12px);
            }
          }

          &__club-name {
            line-height: 1;
            font-size: calc(var(--scale) * 11px);
            color: #ced4da;
            @media (max-width: 1024px) {
              font-size: calc(var(--scale) * 7px);
            }
            @media (max-width: 480px) {
              font-size: calc(var(--scale) * 6px);
            }
          }
        }
      }
    }

    &--is-16 {
      .row {
        min-height: 40px;
      }

      .star-block {
        @media (max-width: 1024px) {
          min-width: calc(var(--scale) * 34px);
        }
        @media (max-width: 480px) {
          min-width: calc(var(--scale) * 20px);
        }
      }

      .cell {
        &--is-total {
          min-width: calc(var(--scale) * 71px);
          @media (max-width: 1024px) {
            min-width: calc(var(--scale) * 60px);
          }
          @media (max-width: 480px) {
            min-width: auto;
          }
        }

        &--is-shooter {
          .cell {
            &__shooter-texts {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: calc(var(--scale) * 8px);

              @media (max-width: 1024px) {
                grid-template-columns: repeat(1, 1fr);
                gap: calc(var(--scale) * 2px);
              }
            }
          }
        }
      }
    }
  }
}

.top-report-results {
  display: flex;
  gap: 20px;
  font-size: 16px;
  text-align: left;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  > div {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 8px;
  }
}

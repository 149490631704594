.filter {
  &__item--is-lag-time {
    align-items: baseline;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__item-title--is-lag-time {
    font-weight: 100;
    font-size: 12px;
    line-height: 14px;
    flex-grow: 1;
  }

  &__item-lag {
    display: flex;
    font-weight: 500;
    font-family: $main-font;
    font-size: 13px;
    color: $text-color;
    letter-spacing: 0;
    line-height: 14px;
    z-index: 2;
    margin-right: 10px;
    flex-shrink: 0;
  }

  &__item-time {
    display: flex;
    font-family: $roboto-mono;
    font-size: 12px;
    color: $text-color;
    letter-spacing: 0;
    line-height: 14px;
    z-index: 2;
    padding-right: 5px;
  }
}

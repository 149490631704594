.detailed-table {
  .row {
    &--up-to-1200 {
      @media (max-width: 1199px) {
        flex-flow: column;

        .score-block {
          font-size: 12px;
          line-height: 22px;
        }
      }

      &.row {
        &__primary {
          flex-flow: row;
          width: 100%;

          @media (min-width: 768px) {
            min-height: 60px;
          }

          @media (max-width: 1199px) {
            &:hover,
            &:active {
              box-shadow: none;
            }

            svg {
              display: block;
            }
          }

          &--is-opened {
            @media (max-width: 1199px) {
              &:before {
                width: 4px;
                opacity: 1;
              }
            }
          }
        }

        &__secondary {
          &--is-opened {
            display: flex;

            @media (min-width: 1200px) {
              display: none;
            }
          }
        }
      }

      .cell {
        &--is-total {
          @media (max-width: 1199px) {
            padding-right: 25px;

            &.cell--has-mid-padding-right {
              padding-right: 35px;
            }
          }
        }
        &--is-total-star {
          @media (max-width: 1199px) {
            min-width: 100px;
            flex-flow: column;
            justify-content: center;

            .star-block {
              font-size: 10px;
              color: $text-color;
              line-height: 6px;
              font-weight: 100;

              &:after {
                display: none;
              }

              &:before {
                font-size: 12px;
                top: 2px;
              }
            }

            .score-block {
              font-size: 12px;
              line-height: 22px;
            }

            .cell {
              &--is-total {
                margin-bottom: 4px;
                justify-content: center;
                padding: 0;
              }
            }
          }
        }

        &--is-shooter {
          @media (max-width: 1199px) {
            @include cell-right-border;
          }
        }

        &.cell--is--aligned-end {
          @media (max-width: 1199px) {
            align-items: flex-end;
            padding-right: 10px;

            .cell {
              &--is-total {
                padding: 0 25px 0 10px;
                justify-content: flex-end;
              }

              &--is-star {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin: 4px 0 0;
  padding-left: 0;

  &__item-wrapper {
    display: flex;
    align-items: center;
    color: $ad-grey-2;
    font-family: $main-font;
    font-size: 14.4px;
    letter-spacing: 0;
    line-height: 17px;

    &:not(:last-of-type) {
      margin-right: 14px;
    }
  }

  &__item-arrow {
    color: $ad-grey-1;
    width: 5px;
    height: 100%;
    margin-left: 13px;
  }

  &__item {
    text-decoration: none;
    color: $ad-grey-1;
  }
}

@import 'variables';

.select-label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: $main-font;
  font-weight: 500;
  font-size: 13px;
  color: $text-color;
  letter-spacing: 0;
  line-height: 17px;
  border-radius: 4px;
  background: $radio-color;
  overflow: hidden;
  padding: 5px 10px 5px 14px;
  height: 30px;
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;

  &__icon {
    width: 7px;
    height: 7px;
    margin-left: 19px;
    cursor: pointer;
  }
}

.admin-menu-controls {
  display: flex;
  align-items: flex-start;

  &__title {
    margin-right: auto;
    font-family: $main-font;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $ad-text-color;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__button {
    @extend %admin-round-button;

    width: 30px;
    height: 30px;
    padding: 0;
    margin-top: -7px;
  }

  &__button-dot {
    color: $ad-grey-1;
    height: 14px;
  }
}

.admin-modal {
  &__row {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  &__delete-button {
    @extend %admin-round-button;

    width: 30px;
    height: 30px;
    padding: 5px 9px;
  }

  &__delete-icon {
    width: 100%;
    color: $red;
  }
}

.admin-menu-table {
  &__row {
    grid-template-columns: 1fr 100px 103px 66px 16px;

    &--is-shooters {
      grid-template-columns: 44px 2.5fr 2fr 2fr 225px 30px;
      padding: 0 10px;
      height: 70px;

      @media (max-width: 767px) {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        height: auto;

        > span {
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 10px;

          &:nth-last-child(2),
          &:nth-last-child(1) {
            width: auto;
          }
        }
      }

      @media (max-width: 374px) {
        padding: 0;

        span {
          padding-left: 5px;
        }
      }
    }

    &--is-inner {
      grid-template-columns: 1fr 100px 103px 62px;
    }
  }
}

%basic-admin-form-group {
  margin-bottom: 9px;
  display: flex;
  flex-flow: column;
  width: 100%;
}

%basic-admin-form-style {
  border-radius: 4px;
  border: 1px solid $ad-light-grey-3;

  &:focus {
    outline: none;
  }
}

%basic-admin-form-text {
  font-family: $main-font;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: $ad-grey-1;
}

%basic-admin-form-heading {
  font-family: $main-font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $ad-grey-1;
  margin-bottom: 5px;
}

.admin-select {
  @extend %basic-admin-form-group;

  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    background: $white;
    height: 36px;
    top: 1px;
    right: 1px;
    width: 30px;
    border-radius: 4px;
    pointer-events: none;
  }

  &__heading {
    @extend %basic-admin-form-heading;
  }

  &__arrow {
    width: 9px;
    color: $ad-grey-1;
    position: absolute;
    right: 14px;
    top: 16px;
    z-index: 3;
    pointer-events: none;
  }

  &__element {
    @extend %basic-admin-form-style;
    @extend %basic-admin-form-text;

    position: relative;
    padding-left: 7px;
    height: 38px;
    width: 100%;

    &--has-error {
      border-color: $red;
    }
  }

  &--has-heading {
    .admin-select {
      &__arrow {
        top: 37px;
      }
    }

    &:after {
      top: 22px;
    }
  }
}

.admin-input {
  @extend %basic-admin-form-group;

  &__heading {
    @extend %basic-admin-form-heading;
  }

  &__element {
    @extend %basic-admin-form-style;
    @extend %basic-admin-form-text;

    width: 100%;
    height: 38px;
    padding: 10px 10px 10px 11px;

    &--is-textarea {
      min-height: 200px;
      height: auto;
      resize: vertical;
    }

    &--has-error {
      border-color: $red;
    }
  }

  &--is-order {
    width: 40px;
    margin-top: auto;
    margin-bottom: auto;

    .admin-input__element {
      text-align: center;
      font-weight: 500;
      color: $ad-text-color;
    }
  }
}

.admin-form-message {
  @extend %basic-admin-form-heading;

  margin-bottom: 0;
  margin-top: 5px;

  &--is-error {
    color: $red;
  }
}

.admin-button {
  @extend %admin-button;

  min-width: 140px;

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  &--is-disabled {
    background: rgba($main-color, 0.9);
    cursor: not-allowed;
  }

  &--is-modal-submit {
    margin-left: auto;
  }

  &--is-text {
    background: none;
    color: $main-color;
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;

    &:hover {
      background: none;
    }
  }

  &--is-shooter-link {
    min-width: 180px;
    margin-right: 15px;
  }
}

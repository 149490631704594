.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: $wrapper-width;
  padding: 0 $wrapper-padding;
}

.full-page-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 0 30px;

  @media (max-width: 767px) {
    padding: 0 15px;
  }

  &--is-fluid {
    padding: 0;
  }

  &--is-full-width {
    max-width: 100%;
  }
}

.content-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  overflow-anchor: none;

  @media (min-width: 1440px) {
    width: calc(100% - 300px);
  }

  @media (max-width: 767px) {
    padding: 0 25px;
  }
}

.load-more-wrapper {
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

html {
  @media (min-width: 1440px) {
    overflow: visible !important;
  }
}

@import 'variables';

.sidebar {
  width: 300px;
  max-width: 100%;
  flex-shrink: 0;
  transition: all 0.2s ease-in-out;
  background: $body-color;

  @media (max-width: 1439px) {
    position: fixed;
    bottom: 0;
    top: 130px;
    z-index: 2;
    transform: translateX(-150%);
    width: 100%;
    max-width: 414px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &--is-visible {
      transform: translateX(0);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__under-layer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    @media (min-width: 1440px) {
      display: none;
    }
  }

  &__button {
    display: none;

    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
    box-shadow: 0 2px 4px 0 rgba(46, 56, 77, 0.3);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: $main-color;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border: none;

    svg {
      width: 28px;
      height: 6px;
    }

    &--is-opened {
      svg {
        width: 20px;
        height: 20px;
      }

      g {
        fill: #fff;
      }
    }

    @media (max-width: 1439px) {
      display: flex;
    }
  }
}

@import 'mixins';

.iframe-wrapper {
  width: 100%;
  height: calc(#{height-vh(100)} - 130px);
  display: flex;

  iframe {
    width: 100%;
    height: 100%;
  }
}

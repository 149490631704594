@import 'variables';

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;

  &--has-small-mt {
    margin-top: 20px;
  }

  p {
    font-size: 24px;
  }
}

@import 'variables';

.char-block {
  height: 28px;
  width: 28px;
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-family: $main-font;
  font-weight: 600;
  font-size: 12px;
  color: $cool-grey;
  letter-spacing: 0;
  text-align: center;
  line-height: 14px;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.15;
    border-radius: 4px;
    border: 1px solid $block-color;
    background: rgba($text-color, 0.5);
  }
}

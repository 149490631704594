@import 'variables';

.type-heading {
  display: flex;
  flex-flow: column wrap;
  padding-top: 30px;

  @media (max-width: 767px) {
    padding-top: 13px;
  }

  &--is-ranged-field {
    margin-bottom: 25px;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  &--is-multiple-shooters {
    margin-bottom: 25px;
    flex-flow: row nowrap;
    align-items: flex-end;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }

    .type-heading {
      &__main-info {
        font-size: 21px;
        margin-right: 20px;

        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 31px;
        }
      }
    }
  }

  &--is-time {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__main-info {
    font-weight: 600;
    font-family: $main-font;
    font-size: 15px;
    color: $text-color;
    letter-spacing: 1.12px;
    margin-top: 5px;
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 12px;
      letter-spacing: 0.9px;
    }
  }

  &__secondary-info {
    font-weight: 100;
    font-family: $main-font;
    font-size: 24px;
    color: $text-color;
    letter-spacing: 0;
    line-height: 32px;

    @media (max-width: 767px) {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
      margin-top: -7px;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column wrap;

    &--is-right {
      align-items: flex-end;
    }
  }

  &__time {
    font-family: $main-font;
    font-weight: 500;
    font-size: 13px;
    color: $text-color;
    letter-spacing: 0;
    line-height: 28px;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  &__pre-title {
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 0;
    font-family: $main-font;
    font-weight: 600;
    font-size: 12px;
    color: $dim-color;
    letter-spacing: 1.12px;

    @media (max-width: 767px) {
      margin-top: 7px;
      font-size: 10px;
      letter-spacing: 0.94px;
    }
  }
}

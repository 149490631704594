@import 'variables';

.dropdown {
  display: flex;
  flex-flow: column wrap;
  width: calc(100% - 20px);
  margin: 30px 10px;

  font-family: $main-font;
  background: #ffffff;
  border: 1px solid $input-border;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  &__label {
    min-height: 58px;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 20px;
    font-weight: 600;
    font-size: 20px;
    color: $text-color;
    letter-spacing: 0;
    cursor: pointer;
    user-select: none;
  }

  &__arrow {
    height: 8px;
    width: 13px;
    margin-left: auto;
    transform: rotate(-90deg);

    path {
      fill: $text-color;
    }

    &--is-opened {
      transform: none;
    }
  }

  &__options {
    display: none;
    list-style: none;
    padding: 0;
    margin-top: 0;

    &--is-opened {
      display: flex;
      flex-flow: column wrap;
    }
  }

  &__option {
    min-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding-left: 40px;
    padding-right: 15px;

    &--is-selected {
      background: $radio-color;
    }
  }
}

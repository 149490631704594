@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
.animFadeIn {
	animation: fadeIn .5s ease-in-out;
}

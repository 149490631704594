.detailed-table {
  .head-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;

    font-family: $main-font;
    font-weight: 600;
    font-size: 12px;
    color: $dim-color;
    text-transform: uppercase;
    letter-spacing: 1.12px;
    padding: 0 10px;
    white-space: nowrap;

    @media (max-width: 767px) {
      font-size: 10px;
    }

    &--is-number {
      width: 60px;

      @media (max-width: 767px) {
        width: 30px;
        padding: 0;
      }
    }

    &--is-shooter {
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-start;
      box-sizing: border-box;
      padding-left: 55px;

      @media (max-width: 767px) {
        padding-left: 10px;
        padding-right: 0;
      }
    }

    &--is-class {
      min-width: 74px;
    }

    &--is-letters {
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;

      .head-cell__text {
        display: flex;
        justify-content: center;
        width: 28px;

        &:not(:last-child) {
          margin-right: 2px;
        }
      }
    }

    &--is-score {
      min-width: 81px;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 18px;
    }

    &--is-total {
      min-width: 74px;
      justify-content: flex-end;
      padding-right: 11px;

      @media (max-width: 767px) {
        justify-content: center;
      }
    }

    &--is-secondary-head {
      flex-grow: 1;
      justify-content: flex-start;
    }

    &--is-star {
      min-width: 45px;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 4px;
    }

    &--is-total-star {
      padding: 0;

      @media (max-width: 767px) {
        justify-content: flex-start;
        min-width: 100px;
        padding-left: 12px;

        .head-cell {
          &--is-total {
            padding: 0;
            min-width: 0;
            justify-content: flex-end;

            &:after {
              content: '/';
              margin-left: 5px;
              margin-right: 3px;
            }
          }
          &--is-star {
            padding: 0;
            min-width: 0;
          }
        }
      }
    }

    &--is-additional {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .head-row {
    &--up-to-1200 {
      .head-cell--is-total-star {
        padding: 0;

        @media (max-width: 1199px) {
          justify-content: flex-start;
          min-width: 100px;
          padding-left: 12px;

          .head-cell {
            &--is-total {
              padding: 0;
              min-width: 0;
              justify-content: flex-end;

              &:after {
                content: '/';
                margin-left: 5px;
                margin-right: 3px;
              }
            }
            &--is-star {
              padding: 0;
              min-width: 0;
            }
          }
        }
      }
    }
  }
}

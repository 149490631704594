.detailed-table {
  .cell {
    &--is-number {
      width: 60px;
      justify-content: center;

      @media (max-width: 767px) {
        @include cell-right-border;

        width: 30px;
        padding: 0;
      }

      .cell__text {
        font-family: $roboto-mono;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 1.57;

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}

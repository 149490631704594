.detailed-table {
  .cell {
    &--is-startlist {
      padding-left: 36px;
    }

    &--is-shooter {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;

      @media (max-width: 767px) {
        @include cell-right-border;

        padding-left: 0;
      }

      .cell {
        &__image {
          height: 34px;
          width: 34px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          flex-shrink: 0;
          line-height: 0;

          &--has-no-image {
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: $main-font;
            font-weight: 600;
            font-size: 14px;
            color: $text-color;
            text-transform: uppercase;
            letter-spacing: 0;
            text-align: center;
            background: rgba($cooler-grey, 0.15);
            box-sizing: border-box;
            padding-bottom: 1px;
          }

          @media (max-width: 767px) {
            height: 24px;
            width: 24px;
            margin-left: 10px;
          }
        }

        &__shooter-texts {
          display: flex;
          flex-flow: column wrap;
          padding-left: 10px;
        }

        &__shooter-name {
          font-family: $main-font;
          font-weight: 500;
          font-size: 16px;
          color: $text-color;
          letter-spacing: 0;
          transform: translateY(4px);

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }

        &__club-name {
          font-family: $main-font;
          font-size: 14px;
          color: $dim-color;
          letter-spacing: 0;
          line-height: 1.57;
          transform: translateY(-1px);

          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
            transform: none;
            padding: 3px 0;
          }
        }
      }
    }
  }
}

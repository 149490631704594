@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: local("Roboto Italic"), local("Roboto-Italic"), url(../fonts/Roboto-Italic.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: fallback;
  src: local("Roboto Light"), local("Roboto-Light"), url(../fonts/Roboto-Light.woff2) format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local("Roboto"), local("Roboto-Regular"), url(../fonts/Roboto-Regular.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(../fonts/Roboto-Medium.woff) format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(../fonts/Roboto-Bold.woff) format("woff"); }

@font-face {
  font-family: 'Roboto Mono';
  src: local("Roboto Mono Medium"), local("RobotoMono-Medium"), url("../fonts/RobotoMono-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto Mono';
  src: local("Roboto Mono"), local("RobotoMono-Regular"), url("../fonts/RobotoMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto Mono';
  src: local("Roboto Mono Bold"), local("RobotoMono-Bold"), url("../fonts/RobotoMono-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto Mono';
  src: local("Roboto Mono Light"), local("RobotoMono-Light"), url("../fonts/RobotoMono-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html {
  box-sizing: border-box;
  height: 100%;
  line-height: 1.4; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e384d;
  background-color: #fbfaf9;
  -webkit-overflow-scrolling: touch; }
  body.ReactModal__Body--open {
    height: 100vh;
    width: 100vw;
    overflow: hidden; }

.overflowHidden {
  overflow: hidden; }

#root {
  flex: 1 0 auto; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  color: #337ab7; }
  a:hover {
    text-decoration: none; }

p {
  font-size: 18px; }

img {
  max-width: 100%; }

.flex-column {
  display: flex;
  flex-flow: column wrap; }

@media (max-width: 767px) {
  .hidden-up-to-767 {
    display: none !important; } }

@media (max-width: 1199px) {
  .hidden-up-to-1199 {
    display: none !important; } }

@media (max-width: 1439px) {
  .hidden-up-to-1439 {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-from-768 {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-from-1200 {
    display: none !important; } }

@media (min-width: 1440px) {
  .hidden-from-1440 {
    display: none !important; } }

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 780px;
  padding: 0 10px; }

.full-page-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 0 30px; }
  @media (max-width: 767px) {
    .full-page-wrapper {
      padding: 0 15px; } }
  .full-page-wrapper--is-fluid {
    padding: 0; }
  .full-page-wrapper--is-full-width {
    max-width: 100%; }

.content-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  overflow-anchor: none; }
  @media (min-width: 1440px) {
    .content-wrapper {
      width: calc(100% - 300px); } }
  @media (max-width: 767px) {
    .content-wrapper {
      padding: 0 25px; } }

.load-more-wrapper {
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }

@media (min-width: 1440px) {
  html {
    overflow: visible !important; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.animFadeIn {
  animation: fadeIn .5s ease-in-out; }

.btn {
  appearance: none;
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  font-weight: 500;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border: none;
  border-radius: 4px;
  background-color: #337ab7;
  transition: background-color 0.25s ease-out;
  text-decoration: none;
  cursor: pointer; }
  .btn:hover {
    background-color: #658ca9; }
  .btn-big {
    display: block;
    height: 50px; }
  .btn--is-secondary-row {
    height: 40px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px; }
  .btn--default {
    z-index: 1; }
  .btn--has-top-offset {
    margin-top: 10px; }
  .btn--is-full-width {
    width: 100%; }
  .btn--is-loading {
    cursor: not-allowed;
    background-color: #658ca9; }

.badge {
  appearance: none;
  display: inline-block;
  vertical-align: top;
  min-width: 28px;
  padding: 0 3px;
  height: 26px;
  line-height: 26px;
  font-weight: 700;
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  color: #69707f;
  text-align: center;
  border-radius: 4px;
  background-color: #eff0f1; }
  .badge.bold-badge {
    font-weight: bold;
    min-width: 40px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    color: #658ca9; }
  .badge__accent {
    color: #2e384d;
    font-size: 11px; }

.header-btn {
  appearance: none;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  color: #337ab7;
  text-transform: uppercase;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none; }
  .header-btn:hover, .header-btn:active {
    color: #658ca9; }
  @media (max-width: 767px) {
    .header-btn {
      flex-direction: column;
      font-size: 13px; }
      .header-btn svg {
        margin-bottom: 5px; } }
  .header-btn svg {
    fill: currentColor; }
  @media (max-width: 767px) {
    .header-btn__menu {
      align-items: flex-start; } }
  .header-btn__menu svg {
    width: 20px;
    height: 18px;
    margin-right: 30px; }
    @media (max-width: 767px) {
      .header-btn__menu svg {
        order: 0;
        margin-right: 0; } }
  @media (max-width: 767px) {
    .header-btn__menu span {
      order: 1; } }
  @media (max-width: 767px) {
    .header-btn__search {
      align-items: flex-end; } }
  .header-btn__search svg {
    width: 20px;
    height: 20px;
    margin-left: 30px; }
    @media (max-width: 767px) {
      .header-btn__search svg {
        order: 0;
        margin-left: 0; } }
  @media (max-width: 767px) {
    .header-btn__search span {
      order: 1; } }

.input {
  appearance: none;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  color: #2e384d;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: all 0.4s; }
  .input:focus {
    border-color: #337ab7; }

.admin-button, .admin-table__button, .admin-table__link-button {
  cursor: pointer;
  border: 1px solid #337ab7;
  border-radius: 5px;
  background: #337ab7;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.167;
  text-transform: uppercase;
  color: #fff;
  transition: 300ms all ease;
  padding: 7px 24px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .admin-button:focus, .admin-table__button:focus, .admin-table__link-button:focus {
    outline: none; }
  .admin-button:hover, .admin-table__button:hover, .admin-table__link-button:hover {
    background: rgba(51, 122, 183, 0.9); }

.admin-table__row-pen-button, .admin-menu-controls__button, .admin-modal__delete-button, .layout-top-bar-user__dropdown-item-button {
  transition: 300ms all ease; }
  .admin-table__row-pen-button:hover, .admin-menu-controls__button:hover, .admin-modal__delete-button:hover, .layout-top-bar-user__dropdown-item-button:hover {
    background: rgba(80, 93, 105, 0.07);
    cursor: pointer; }
  .admin-table__row-pen-button:focus, .admin-menu-controls__button:focus, .admin-modal__delete-button:focus, .layout-top-bar-user__dropdown-item-button:focus {
    outline: none;
    background: rgba(80, 93, 105, 0.1); }

.admin-table__row-pen-button, .admin-menu-controls__button, .admin-modal__delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  border-radius: 50%; }

.notifications-provider {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  flex-flow: column;
  align-items: flex-end; }

.notification-enter, .notification-appear {
  transform: translateX(calc(100% + 20px));
  transition: all 0.3s; }

.notification-enter-active, .notification-enter-done, .notification-appear-active {
  transform: translateX(0);
  transition: all 0.3s; }

.notification-exit {
  transform: translateX(0);
  transition: all 0.2s; }

.notification-exit-active {
  transform: translateX(calc(100% + 20px));
  transition: all 0.2s; }

.breadcrumbs {
  display: flex;
  align-items: center;
  margin: 4px 0 0;
  padding-left: 0; }
  .breadcrumbs__item-wrapper {
    display: flex;
    align-items: center;
    color: #74788D;
    font-family: "Roboto", sans-serif;
    font-size: 14.4px;
    letter-spacing: 0;
    line-height: 17px; }
    .breadcrumbs__item-wrapper:not(:last-of-type) {
      margin-right: 14px; }
  .breadcrumbs__item-arrow {
    color: #505d69;
    width: 5px;
    height: 100%;
    margin-left: 13px; }
  .breadcrumbs__item {
    text-decoration: none;
    color: #505d69; }

.admin-select, .admin-input {
  margin-bottom: 9px;
  display: flex;
  flex-flow: column;
  width: 100%; }

.admin-select__element, .admin-input__element {
  border-radius: 4px;
  border: 1px solid #CED4DA; }
  .admin-select__element:focus, .admin-input__element:focus {
    outline: none; }

.admin-select__element, .admin-input__element {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #505d69; }

.admin-select__heading, .admin-input__heading, .admin-form-message {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #505d69;
  margin-bottom: 5px; }

.admin-select {
  position: relative;
  cursor: pointer; }
  .admin-select:after {
    content: '';
    position: absolute;
    z-index: 2;
    background: #fff;
    height: 36px;
    top: 1px;
    right: 1px;
    width: 30px;
    border-radius: 4px;
    pointer-events: none; }
  .admin-select__arrow {
    width: 9px;
    color: #505d69;
    position: absolute;
    right: 14px;
    top: 16px;
    z-index: 3;
    pointer-events: none; }
  .admin-select__element {
    position: relative;
    padding-left: 7px;
    height: 38px;
    width: 100%; }
    .admin-select__element--has-error {
      border-color: #FF3D3D; }
  .admin-select--has-heading .admin-select__arrow {
    top: 37px; }
  .admin-select--has-heading:after {
    top: 22px; }

.admin-input__element {
  width: 100%;
  height: 38px;
  padding: 10px 10px 10px 11px; }
  .admin-input__element--is-textarea {
    min-height: 200px;
    height: auto;
    resize: vertical; }
  .admin-input__element--has-error {
    border-color: #FF3D3D; }

.admin-input--is-order {
  width: 40px;
  margin-top: auto;
  margin-bottom: auto; }
  .admin-input--is-order .admin-input__element {
    text-align: center;
    font-weight: 500;
    color: #343A40; }

.admin-form-message {
  margin-bottom: 0;
  margin-top: 5px; }
  .admin-form-message--is-error {
    color: #FF3D3D; }

.admin-button {
  min-width: 140px; }
  .admin-button:not(:last-of-type) {
    margin-right: 20px; }
  .admin-button--is-disabled {
    background: rgba(51, 122, 183, 0.9);
    cursor: not-allowed; }
  .admin-button--is-modal-submit {
    margin-left: auto; }
  .admin-button--is-text {
    background: none;
    color: #337ab7;
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
    border: none; }
    .admin-button--is-text:hover {
      background: none; }
  .admin-button--is-shooter-link {
    min-width: 180px;
    margin-right: 15px; }

.layout {
  display: flex;
  height: 100vh;
  position: relative; }
  .layout__sidebar {
    flex-basis: 240px;
    background: #252B3B;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.08);
    position: relative; }
    @media (max-width: 1023px) {
      .layout__sidebar {
        transition: 300ms all ease;
        width: 240px;
        max-width: calc(100vw - 30px);
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        z-index: 2; }
        .layout__sidebar--is-opened {
          transform: translateX(100%); } }
  .layout__sidebar-button {
    position: absolute;
    left: 100%;
    height: 30px;
    width: 30px;
    background: #252B3B;
    border: none;
    top: 20px;
    padding: 7px;
    color: #fff;
    cursor: pointer; }
    .layout__sidebar-button svg {
      height: 100%;
      width: 100%;
      fill: currentColor; }
    .layout__sidebar-button:focus {
      outline: none; }
    @media (min-width: 1024px) {
      .layout__sidebar-button {
        display: none; } }
  .layout__logo-wrapper {
    display: flex;
    flex-flow: column;
    padding: 20px 24px 0 20px; }
    .layout__logo-wrapper:after {
      content: '';
      height: 1px;
      width: 100%;
      background: #323642;
      margin-top: 19px; }
  .layout__logo-link {
    display: flex; }
  .layout__logo {
    width: 100%;
    height: 100%; }
    .layout__logo path {
      fill: #fff !important; }
  .layout__sidebar-links {
    display: flex;
    flex-flow: column;
    padding-left: 0;
    margin-top: 9px;
    margin-bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  .layout__sidebar-link-item {
    display: flex;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 13.3px;
    line-height: 16px;
    color: #8590A5;
    padding: 14px 15px 13px 24px;
    width: 100%; }
    .layout__sidebar-link-item--is-active {
      background: #337ab7;
      color: #D7E4EC; }
  .layout__sidebar-link-icon-wrapper {
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; }
  .layout__sidebar-link-icon {
    transform: translateY(-1px); }
  .layout__sidebar-link-wrapper {
    list-style: none;
    display: flex; }
  .layout__top-bar {
    padding: 20px 16px 20px 20px;
    display: flex;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .layout__years {
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
    margin: 0; }
  .layout__year {
    list-style: none;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 10px;
    min-width: 60px; }
    .layout__year--is-active {
      padding: 8px 16px;
      color: #fff;
      background: #337ab7;
      font-weight: bold; }
  .layout__year-button {
    border-radius: 5px;
    background: #f4f4f4;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #69707f;
    padding: 8px 16px;
    width: 100%;
    text-decoration: none; }
    .layout__year-button:focus {
      outline: none; }
    .layout__year-button:hover {
      cursor: pointer;
      background: #dbdbdb; }

.layout-top-bar-user {
  margin-left: auto;
  display: flex;
  align-items: center;
  min-height: 30px;
  position: relative;
  min-width: 100px;
  justify-content: flex-end; }
  .layout-top-bar-user__button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: #636E75;
    font-family: "Roboto", sans-serif;
    font-size: 14.4px;
    letter-spacing: 0;
    line-height: 17px;
    padding: 0; }
    .layout-top-bar-user__button:focus {
      outline: none; }
    .layout-top-bar-user__button:hover {
      cursor: pointer; }
  .layout-top-bar-user__icon {
    margin-left: 11px;
    height: 5px; }
  .layout-top-bar-user__dropdown {
    display: flex;
    flex-flow: column;
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    margin: 0;
    padding: 5px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0784314);
    border-radius: 4px; }
  .layout-top-bar-user__dropdown-item {
    display: flex; }
  .layout-top-bar-user__dropdown-item-button {
    width: 100%;
    border: none;
    background: none;
    color: #636E75;
    font-family: "Roboto", sans-serif;
    font-size: 14.4px;
    letter-spacing: 0;
    line-height: 1.1805;
    padding: 8px 18px; }

.layout__content {
  flex-grow: 1;
  display: flex;
  flex-flow: column; }

.layout__paper {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0784314);
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px; }

.layout__scroll-content {
  overflow-y: auto;
  flex-grow: 1; }

.layout__page-info {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 20px 20px 25px 25px; }

.layout__page-title {
  margin-right: auto;
  color: #343A40;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 4px;
  margin-bottom: 0; }

.admin-table {
  display: flex;
  flex-flow: column;
  padding-left: 0;
  margin: 17px 0 0; }
  .admin-table--is-inner {
    margin: 0;
    background: #f8f9fa;
    padding: 0 40px;
    position: relative; }
    .admin-table--is-inner:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #eff0f1; }
  .admin-table__row-text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 17px;
    color: #343A40;
    display: flex;
    align-items: center; }
    .admin-table__row-text--is-centered {
      justify-content: center; }
    .admin-table__row-text--is-align-end {
      justify-content: flex-end; }
    .admin-table__row-text--is-inner {
      font-weight: 400;
      font-size: 13px;
      color: rgba(52, 58, 64, 0.5); }
    .admin-table__row-text--is-inner-title {
      font-size: 13px;
      font-weight: 400; }
    .admin-table__row-text--has-right-offset {
      padding-right: 7px; }
    .admin-table__row-text--has-badges .badge {
      margin-left: 5px; }
  .admin-table__row-pen {
    width: 15px;
    color: #337ab7; }
  .admin-table__row-pen-button {
    width: 30px;
    height: 30px; }
  .admin-table__row-arrow {
    width: 9px;
    color: #8590A5; }
    .admin-table__row-arrow--is-active {
      transform: rotate(180deg); }
  .admin-table__row-badge {
    border-radius: 4px;
    background: #eff0f1;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 2px 6px;
    margin-right: 7px; }
    .admin-table__row-badge--is-blue {
      background: rgba(74, 164, 255, 0.18); }
    .admin-table__row-badge--is-green {
      background: rgba(28, 187, 140, 0.18); }
  .admin-table__link-button {
    background: none;
    color: #337ab7;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 1px 4px; }
    .admin-table__link-button:hover {
      background: rgba(51, 122, 183, 0.1); }
  .admin-table__row {
    display: grid;
    grid-template-columns: 1fr;
    height: 50px;
    position: relative;
    padding: 0 20px; }
    .admin-table__row:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #eff0f1; }
    .admin-table__row--can-open {
      cursor: pointer; }
    .admin-table__row--is-heading {
      background: #f4f4f4;
      height: 50px; }
      .admin-table__row--is-heading .admin-table__row-text {
        color: #505d69; }
    .admin-table__row--is-inner {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 1fr; }
    .admin-table__row--is-button {
      display: flex;
      align-items: center;
      height: auto;
      padding: 20px 0; }
      .admin-table__row--is-button:after {
        display: none; }
    .admin-table__row--is-button-outer {
      padding-top: 30px; }
    .admin-table__row--is-edit-buttons {
      justify-content: flex-end; }

.admin-menu-controls {
  display: flex;
  align-items: flex-start; }
  .admin-menu-controls__title {
    margin-right: auto;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #343A40;
    margin-top: 0;
    margin-bottom: 0; }
  .admin-menu-controls__button {
    width: 30px;
    height: 30px;
    padding: 0;
    margin-top: -7px; }
  .admin-menu-controls__button-dot {
    color: #505d69;
    height: 14px; }

.admin-modal__row {
  display: flex;
  align-items: center;
  margin-top: 20px; }

.admin-modal__delete-button {
  width: 30px;
  height: 30px;
  padding: 5px 9px; }

.admin-modal__delete-icon {
  width: 100%;
  color: #FF3D3D; }

.admin-menu-table__row {
  grid-template-columns: 1fr 100px 103px 66px 16px; }
  .admin-menu-table__row--is-shooters {
    grid-template-columns: 44px 2.5fr 2fr 2fr 225px 30px;
    padding: 0 10px;
    height: 70px; }
    @media (max-width: 767px) {
      .admin-menu-table__row--is-shooters {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        height: auto; }
        .admin-menu-table__row--is-shooters > span {
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 10px; }
          .admin-menu-table__row--is-shooters > span:nth-last-child(2), .admin-menu-table__row--is-shooters > span:nth-last-child(1) {
            width: auto; } }
    @media (max-width: 374px) {
      .admin-menu-table__row--is-shooters {
        padding: 0; }
        .admin-menu-table__row--is-shooters span {
          padding-left: 5px; } }
  .admin-menu-table__row--is-inner {
    grid-template-columns: 1fr 100px 103px 62px; }

.admin-reports-table__row {
  grid-template-columns: 1fr 88px 44px 46px;
  padding-right: 0; }

.user-info {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 350px; }

.top-report {
  height: 100vh;
  background-image: url("../images/top_table_bg.jpg");
  background-size: cover;
  overflow: hidden; }
  .top-report .detailed-table {
    --scale: 1;
    overflow-y: auto;
    flex-flow: column nowrap;
    height: 100%;
    margin: 0;
    padding: calc(var(--scale) * 14px); }
    @media (max-width: 1024px) {
      .top-report .detailed-table {
        padding: 12px; } }
    .top-report .detailed-table.cycle_three_items {
      --scale: 3 !important;
      padding: 15vh calc(var(--scale) * 14px);
      justify-content: center; }
      .top-report .detailed-table.cycle_three_items .row {
        max-height: 20vh; }
        .top-report .detailed-table.cycle_three_items .row:first-child {
          display: none; }
      .top-report .detailed-table.cycle_three_items .cell__shooter-name {
        margin-bottom: 6px; }
    .top-report .detailed-table .row {
      flex-grow: 1; }
      .top-report .detailed-table .row:first-child .row__primary {
        background: #001f3e;
        border: none; }
      .top-report .detailed-table .row:not(:first-child):nth-child(even) .row__primary {
        background: #004a93; }
      .top-report .detailed-table .row:not(:first-child):nth-child(even) .cell--is-total-star {
        background: #e2383f; }
      .top-report .detailed-table .row:not(:first-child):nth-child(odd) .row__primary {
        background: #013b76; }
      .top-report .detailed-table .row:not(:first-child):nth-child(odd) .cell--is-total-star {
        background: #b52d31; }
      .top-report .detailed-table .row__primary {
        border: none; }
        .top-report .detailed-table .row__primary:hover:before {
          display: none; }
        @media (max-width: 1024px) {
          .top-report .detailed-table .row__primary {
            height: 100%; } }
      .top-report .detailed-table .row:not(:last-child) {
        margin-bottom: 0; }
    .top-report .detailed-table .score-block {
      line-height: 1;
      font-size: calc(var(--scale) * 20px);
      color: #fff; }
      @media (max-width: 1024px) {
        .top-report .detailed-table .score-block {
          font-size: calc(var(--scale) * 12px); } }
      @media (max-width: 480px) {
        .top-report .detailed-table .score-block {
          font-size: calc(var(--scale) * 8px); } }
    .top-report .detailed-table .star-block {
      font-size: calc(var(--scale) * 14px);
      width: 100px;
      color: #fff;
      background: none;
      border-radius: calc(var(--scale) * 4px) 0 0 calc(var(--scale) * 4px); }
      @media (max-width: 1024px) {
        .top-report .detailed-table .star-block {
          font-size: calc(var(--scale) * 10px) !important;
          color: #fff !important;
          width: auto;
          padding-right: 10px; } }
      .top-report .detailed-table .star-block:before {
        font-size: calc(var(--scale) * 8px); }
      .top-report .detailed-table .star-block:after {
        display: none; }
    .top-report .detailed-table .shooter_img {
      width: calc(var(--scale) * 60px);
      padding: calc(var(--scale) * 10px) 0;
      flex-shrink: 0; }
      .top-report .detailed-table .shooter_img img {
        height: 100%;
        object-fit: cover; }
      @media (max-width: 1024px) {
        .top-report .detailed-table .shooter_img {
          display: none; } }
    .top-report .detailed-table .cell {
      padding: 0 calc(var(--scale) * 10px); }
      @media (max-width: 1024px) {
        .top-report .detailed-table .cell {
          padding: 0 calc(var(--scale) * 6px); } }
      .top-report .detailed-table .cell__shooter-texts {
        flex-grow: 1; }
      .top-report .detailed-table .cell__shooter-name {
        flex-grow: 1;
        display: flex;
        align-items: center; }
      .top-report .detailed-table .cell__club-name {
        flex-grow: 1;
        display: flex;
        align-items: center; }
      .top-report .detailed-table .cell--is-number {
        width: calc(var(--scale) * 40px);
        padding: 0 calc(var(--scale) * 4px); }
        @media (max-width: 1024px) {
          .top-report .detailed-table .cell--is-number {
            width: calc(var(--scale) * 28px);
            padding: 0 calc(var(--scale) * 2px); } }
        .top-report .detailed-table .cell--is-number:after {
          display: none; }
        .top-report .detailed-table .cell--is-number span {
          background: #fff;
          color: #000;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(var(--scale) * 20px);
          height: calc(var(--scale) * 20px);
          line-height: 1;
          font-size: calc(var(--scale) * 14px); }
          @media (max-width: 1024px) {
            .top-report .detailed-table .cell--is-number span {
              font-size: calc(var(--scale) * 10px);
              width: calc(var(--scale) * 14px);
              height: calc(var(--scale) * 14px); } }
      .top-report .detailed-table .cell--is-total-star, .top-report .detailed-table .cell--is-star {
        padding: 0; }
      .top-report .detailed-table .cell--is-total {
        padding-right: calc(var(--scale) * 10px); }
        @media (max-width: 1024px) {
          .top-report .detailed-table .cell--is-total {
            padding-right: calc(var(--scale) * 4px); } }
        @media (max-width: 480px) {
          .top-report .detailed-table .cell--is-total {
            min-width: auto; } }
      .top-report .detailed-table .cell--is-total-star {
        background: #dc2f34; }
        @media (max-width: 1024px) {
          .top-report .detailed-table .cell--is-total-star {
            flex-direction: row; } }
      .top-report .detailed-table .cell--is-shooter::after {
        display: none; }
      .top-report .detailed-table .cell--is-shooter.heading .cell__shooter-name {
        justify-content: center; }
      .top-report .detailed-table .cell--is-shooter .cell__shooter-name {
        line-height: 1;
        font-size: calc(var(--scale) * 15px);
        transform: none;
        color: #fff; }
        @media (max-width: 1024px) {
          .top-report .detailed-table .cell--is-shooter .cell__shooter-name {
            font-size: calc(var(--scale) * 10px); } }
        @media (max-width: 480px) {
          .top-report .detailed-table .cell--is-shooter .cell__shooter-name {
            font-size: calc(var(--scale) * 8px); } }
      .top-report .detailed-table .cell--is-shooter .cell__shooter-logo {
        height: calc(var(--scale) * 20px); }
        .top-report .detailed-table .cell--is-shooter .cell__shooter-logo + .cell__shooter-name {
          padding: 0 10px;
          margin: 0 auto; }
        @media (max-width: 1024px) {
          .top-report .detailed-table .cell--is-shooter .cell__shooter-logo {
            margin: 0 8px; } }
        @media (max-width: 480px) {
          .top-report .detailed-table .cell--is-shooter .cell__shooter-logo {
            height: calc(var(--scale) * 12px); } }
      .top-report .detailed-table .cell--is-shooter .cell__club-name {
        line-height: 1;
        font-size: calc(var(--scale) * 11px);
        color: #ced4da; }
        @media (max-width: 1024px) {
          .top-report .detailed-table .cell--is-shooter .cell__club-name {
            font-size: calc(var(--scale) * 7px); } }
        @media (max-width: 480px) {
          .top-report .detailed-table .cell--is-shooter .cell__club-name {
            font-size: calc(var(--scale) * 6px); } }
    .top-report .detailed-table--is-16 .row {
      min-height: 40px; }
    @media (max-width: 1024px) {
      .top-report .detailed-table--is-16 .star-block {
        min-width: calc(var(--scale) * 34px); } }
    @media (max-width: 480px) {
      .top-report .detailed-table--is-16 .star-block {
        min-width: calc(var(--scale) * 20px); } }
    .top-report .detailed-table--is-16 .cell--is-total {
      min-width: calc(var(--scale) * 71px); }
      @media (max-width: 1024px) {
        .top-report .detailed-table--is-16 .cell--is-total {
          min-width: calc(var(--scale) * 60px); } }
      @media (max-width: 480px) {
        .top-report .detailed-table--is-16 .cell--is-total {
          min-width: auto; } }
    .top-report .detailed-table--is-16 .cell--is-shooter .cell__shooter-texts {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: calc(var(--scale) * 8px); }
      @media (max-width: 1024px) {
        .top-report .detailed-table--is-16 .cell--is-shooter .cell__shooter-texts {
          grid-template-columns: repeat(1, 1fr);
          gap: calc(var(--scale) * 2px); } }

.top-report-results {
  display: flex;
  gap: 20px;
  font-size: 16px;
  text-align: left; }
  @media (max-width: 767px) {
    .top-report-results {
      flex-direction: column; } }
  .top-report-results > div {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 4px; }
  .top-report-results p {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 8px; }

.iframe-wrapper {
  width: 100%;
  height: calc(calc(100 * var(--height-point)) - 130px);
  display: flex; }
  .iframe-wrapper iframe {
    width: 100%;
    height: 100%; }

.site-menu {
  height: 50px;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: #fbfaf9; }
  .site-menu:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    content: '';
    background: #eee;
    z-index: 1; }
  .site-menu::-webkit-scrollbar {
    display: none; }
  .site-menu__item {
    list-style: none;
    display: flex;
    flex-shrink: 0;
    position: relative;
    z-index: 2; }
  .site-menu__item-link {
    height: calc(100% - 3px);
    display: flex;
    position: relative;
    color: #337ab7;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    transition: 300ms all ease-in-out;
    align-items: center;
    font-weight: 500; }
    .site-menu__item-link:hover {
      background: #eee; }
    .site-menu__item-link:after {
      transition: 300ms all ease-in-out;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 3px;
      z-index: 3;
      background: #eee; }
    .site-menu__item-link--is-active {
      background: #eee; }
      .site-menu__item-link--is-active:after {
        background: #337ab7; }

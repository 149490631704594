@import 'variables';
@import 'navigation';

.site {
  &-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: 80px;
    padding: 0 $main-padding;
    color: $main-color;
    background-color: $body-color;
    border-bottom: 1px solid $separator;

    @media (max-width: 767px) {
      padding: 0 15px;
    }

    &__wrapper {
      position: fixed;
      z-index: 10;
      left: 0;
      top: 0;
      width: 100%;
    }

    &__menu,
    &__logo,
    &__search {
      display: flex;
      align-items: center;
    }

    &__logo {
      position: absolute;
      align-self: center;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 2;

      img {
        height: 40px;
        width: 250px;
      }

      &-year {
        position: absolute;
        right: -1px;
        top: -6px;
        font-style: italic;
        font-weight: 700;
        font-size: 17px;
        color: $logo-red;
        letter-spacing: -0.4px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }
}

@import 'variables';

.content-shooter {
  overflow: hidden;

  &__box-wrap {
    width: 100%;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 100%;
  }

  &__idname {
    width: 100%;
  }

  &__id {
    margin: 0;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.13px;
    color: $cooler-grey;
  }

  &__name {
    margin: 10px 0;
    font-weight: 500;
    font-size: 24px;

    a {
      appearance: none;
      margin-left: 10px;
      border: none;
      background: transparent;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        fill: $dim-color;
      }

      &:hover {
        svg {
          fill: $main-color;
        }
      }
    }
  }

  &__club {
    margin: 5px 0;
    font-size: 18px;
    color: $dim-color;
  }

  .badges {
    margin: 15px 0;
  }

  .badge {
    margin-right: 10px;
  }

  &__avatar {
    &-wrapper {
    }

    flex: none;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;

    img {
      position: absolute;
      z-index: 2;
      max-width: none;
      height: 140px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__tablegraphics {
    width: 100%;

    .btn {
      margin-right: 10px;
      margin-bottom: 5px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  &__graphics {
    &-heading {
      font-weight: 700;
      font-size: 13px;
      line-height: 40px;
      letter-spacing: 0.97px;
      text-transform: uppercase;
    }

    &-subheading {
      margin: 5px 0;
      font-weight: 300;
      font-size: 18px;
      line-height: 32px;
    }

    &-row {
      + .content-shooter__graphics-row {
        margin-top: 20px;
      }
    }
  }

  &__bottom {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  &__full-button {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 50px;
    font-weight: 700;
    font-size: 15px;
    font-family: $main-font;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    background: $back-grey;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: $dim-color;
    }
  }

  .shooterpage & {
    display: flex;

    @media (max-width: 1350px) {
      flex-wrap: wrap;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      flex-wrap: wrap;
    }

    &__top {
      justify-content: flex-start;
    }

    &__idname {
      display: flex;
      justify-content: space-between;
    }

    &__id {
      order: 1;
    }

    &__name {
      order: 0;
      margin-top: 0;
    }

    &__avatar {
      flex: none;
      width: 240px;
      height: 240px;
      margin-right: 60px;

      img {
        height: 240px;
      }
    }

    &__tablegraphics {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      @media (max-width: 1350px) {
        flex-direction: column;
      }
    }

    &__table,
    &__graphics {
      width: calc(50% - 20px);
      margin-top: 20px;

      @media (max-width: 1350px) {
        width: 100%;
      }
    }

    &__table {
      position: relative;
      margin-right: 20px;
      margin-bottom: 20px;
      overflow-x: auto;
      overflow-y: hidden;

      @media (max-width: 767px) {
        margin-right: 0;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 2;
        right: -21px;
        top: 40px;
        height: 100%;
        width: 1px;
        background-color: $back-grey;
        display: none;
      }
    }

    .shooter-table {
      margin-bottom: 0;
    }

    &__graphics {
      margin-left: 20px;
      padding-top: 45px;

      @media (max-width: 1024px) {
        margin-left: 0;
      }

      @media (max-width: 767px) {
        padding-top: 20px;
      }

      &-heading {
        border-top: 1px solid $back-grey;
      }
    }
  }
}

.admin-table {
  display: flex;
  flex-flow: column;
  padding-left: 0;
  margin: 17px 0 0;

  &--is-inner {
    margin: 0;
    background: $ad-light-grey-2;
    padding: 0 40px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: $back-grey;
    }
  }

  &__row-text {
    font-family: $main-font;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4px;
    line-height: 17px;
    color: $ad-text-color;
    display: flex;
    align-items: center;

    &--is-centered {
      justify-content: center;
    }

    &--is-align-end {
      justify-content: flex-end;
    }

    &--is-inner {
      font-weight: 400;
      font-size: 13px;
      color: rgba($ad-text-color, 0.5);
    }

    &--is-inner-title {
      font-size: 13px;
      font-weight: 400;
    }

    &--has-right-offset {
      padding-right: 7px;
    }

    &--has-badges {
      .badge {
        margin-left: 5px;
      }
    }
  }

  &__row-pen {
    width: 15px;
    color: $main-color;
  }

  &__row-pen-button {
    @extend %admin-round-button;

    width: 30px;
    height: 30px;
  }

  &__row-arrow {
    width: 9px;
    color: $ad-grey;

    &--is-active {
      transform: rotate(180deg);
    }
  }

  &__row-badge {
    border-radius: 4px;
    background: $back-grey;
    font-family: $main-font;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 2px 6px;
    margin-right: 7px;

    &--is-blue {
      background: rgba($ad-blue, 0.18);
    }

    &--is-green {
      background: rgba($ad-green, 0.18);
    }
  }

  &__button {
    @extend %admin-button;
  }

  &__link-button {
    @extend %admin-button;

    background: none;
    color: $main-color;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 1px 4px;

    &:hover {
      background: rgba($main-color, 0.1);
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    height: 50px;
    position: relative;
    padding: 0 20px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: $back-grey;
    }

    &--can-open {
      cursor: pointer;
    }

    &--is-heading {
      background: $border-grey;
      height: 50px;

      .admin-table {
        &__row-text {
          color: $ad-grey-1;
        }
      }
    }

    &--is-inner {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 1fr;
    }

    &--is-button {
      display: flex;
      align-items: center;
      height: auto;
      padding: 20px 0;

      &:after {
        display: none;
      }
    }

    &--is-button-outer {
      padding-top: 30px;
    }

    &--is-edit-buttons {
      justify-content: flex-end;
    }
  }
}

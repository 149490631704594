@import 'variables';

.shooter-table {
  width: 630px;
  overflow: auto;
  margin-bottom: 30px;
  border-top: 1px solid $back-grey;

  &__row {
    display: flex;

    &:first-child {
      text-transform: uppercase;
      color: $dim-color;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1.12px;
    }

    &:nth-child(2n) {
      background-color: rgba($back-grey, 0.5);
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 80px;
    height: 40px;
    padding-left: 10px;
    color: $text-color;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1.12px;
    border-right: 2px solid #fff;

    &:first-child {
      width: 100px;
      text-transform: uppercase;
      color: $dim-color;
      font-weight: 700;
      font-size: 11px;
      letter-spacing: 1.03px;
      flex-shrink: 0;
    }

    &:nth-last-child(2) {
      width: 90px;
      flex-shrink: 0;
    }

    &:last-child {
      flex-shrink: 0;
      width: 150px;
      border: none;
    }
  }

  &__klasse {
    margin: 10px 0;
    font-weight: 300;
    font-size: 18px;
    color: $text-color;
  }
}

@import 'variables';

.site-search {
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
  width: 100%;
  height: 120px;

  background-color: $back-grey;
  will-change: transform;

  @media (max-width: 1024px) {
    padding: 0 15px;
  }

  @media (max-width: 767px) {
    height: 60px;
    padding: 0 10px;
  }
}

// CSS Transition
.site {
  &-search {
    &-enter,
    &-appear {
      // opacity: 0;
      transform: translateY(-300px);
    }

    &-enter-active,
    &-appear-active {
      transform: translateY(0);
      // opacity: 1;
      transition: all 0.5s;
    }

    &-exit {
      transform: translateY(0);
      // opacity: 1;
      transition: all 0.5s;
    }

    &-exit-active {
      // opacity: 0;
      transform: translateY(-300px);
    }
  }
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__left {
    position: absolute;
    z-index: 2;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: $text-color;

    @media (max-width: 1280px) {
      display: none;
    }
  }

  &__heading {
    margin: 0;
    margin-bottom: 5px;
    font-weight: 700;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }

  &__subheading {
    margin: 0;
    margin-top: 5px;
    font-style: italic;
    font-size: 14px;
    color: $cool-grey;
  }

  &__center {
  }

  &__form {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  input {
    width: 100%;
    height: 60px;
    padding: 0 150px 0 20px;
    font-weight: 300;
    font-size: 24px;
    background-color: #fff;

    @media (max-width: 767px) {
      height: 40px;
      padding: 0 100px 0 10px;
      font-size: 20px;
    }

    &:focus {
      border-color: $main-color;
    }
  }

  &__focus-bg {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    height: 60px;
  }

  .btn {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 50%;
    height: 40px;
    min-width: 100px;
    font-size: 15px;
    text-transform: uppercase;
    transform: translateY(-50%);
    cursor: pointer;

    @media (max-width: 767px) {
      height: 38px;
      right: 0;
      min-width: 80px;
    }
  }
}

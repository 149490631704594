@mixin cell-right-border {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    right: 0;
    top: 14px;
    bottom: 14px;
    background: $back-grey;

    @media (max-width: 767px) {
      top: 5px;
      bottom: 5px;
    }
  }
}

.detailed-table {
  .cell {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 10px;

    &__text {
      color: $text-color;
    }

    &--has-right-border {
      @include cell-right-border;
    }

    &--is-class {
      justify-content: flex-end;
      min-width: 74px;
      padding-right: 16px;
      box-sizing: border-box;
    }

    &--is-letters {
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;

      .char-block {
        opacity: 0;

        &:not(:last-child) {
          margin-right: 2px;
        }

        &--is-visible {
          opacity: 1;
        }
      }
    }

    &--is-small-letters {
      .char-block {
        @media (max-width: 767px) {
          margin-left: 10px;

          &:after {
            top: 5px;
            bottom: 5px;
          }

          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }

    &--is-score {
      justify-content: flex-end;
      min-width: 81px;
      padding-right: 16px;
      box-sizing: border-box;
    }

    &--is-total {
      justify-content: flex-end;
      min-width: 74px;
      padding-right: 10px;
      box-sizing: border-box;

      @media (max-width: 767px) {
        padding-right: 25px;

        &.cell--has-mid-padding-right {
          padding-right: 35px;
        }
      }
    }

    &--is-star {
      padding: 0;
    }

    &--is-additional {
      @media (max-width: 767px) {
        display: none;
      }
    }

    &--is-thin {
      &,
      * {
        font-weight: 300;
      }
    }

    &--additional-is-shots {
      padding-right: 2px;
    }
  }
}

@import 'cell--is-number';
@import 'cell--is-shooter';
@import 'cell--is-total-star';
@import 'cell--is-shots';

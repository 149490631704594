.shooting-card {
  min-width: 600px;

  &.wide {
    min-width: 800px;
  }

  .header_table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
    td {
      border: 1px solid #ccc;

      p {
        font-size: 32px;
        margin: 0;
      }
    }
  }

  .image-description {
    position: absolute;
    top: 100%;
    p {
      font-size: 14px;
      margin: 0;
    }
  }

  .felt-series-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .felt-serie {
    padding-left: 10px;
    margin-bottom: 40px;
    flex-basis: 50%;

    &:only-child {
      flex-basis: 100%;
      .felt-serie_image {
        display: flex;
        max-width: 340px;
        margin-right: 16px;
      }
      .felt-serie_image_loading {
        width: 340px;
        margin-right: 16px;

        .loader {
          height: 340px;
        }
      }
    }

    .felt-serie_wrap {
      display: flex;
    }

    .felt-serie_title {
      flex: 1;
      font-weight: 500;
    }

    .felt-serie_image {
      position: relative;
      display: flex;
      max-width: 240px;
      margin-right: 16px;
      border: 1px solid #ccc;
    }
    .felt-serie_image_loading {
      width: 240px;
      margin-right: 16px;

      .loader {
        height: 240px;
      }
    }
    .felt-serie_details {
      display: flex;
      flex-direction: column;

      .felt-shot {
        margin-bottom: 8px;
        margin-right: 10px;
      }

      .serie-sum {
        margin-top: auto;
        font-weight: 500;
        p {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }

  .unified-serie {
    padding-left: 10px;
    margin-bottom: 40px;

    .unified-serie_title {
      flex: 1;
      font-weight: 500;
    }

    .unified-serie_wrap {
      display: flex;
    }

    .unified-serie_image {
      position: relative;
      display: flex;
      max-width: 400px;
      margin-right: 16px;
      border: 1px solid #ccc;
    }
    .unified-serie_image_loading {
      width: 400px;
      margin-right: 16px;

      .loader {
        height: 400px;
      }
    }
    .unified-serie_details {
      display: flex;
      flex-direction: column;

      .unified-shot {
        margin-bottom: 8px;
        margin-right: 8px;
      }

      .serie-sum {
        margin-top: auto;
        font-weight: 500;
        p {
          font-size: 14px;
          margin: 0;
        }
      }
    }
    .serie-sum-total p {
      font-size: 26px;
      font-weight: 700;
    }
  }

  .bane-serie {
    padding-left: 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;

    .bane-serie_image {
      display: flex;
      max-width: 200px;
      margin-right: 40px;
      border: 1px solid #ccc;
    }

    .bane-serie_image_loading {
      width: 200px;
      margin-right: 40px;

      .loader {
        height: 200px;
      }
    }

    .bane-serie_details {
      flex: 1;
      font-weight: 500;
      .serie-title {
        border-bottom: 1px solid #ccc;
        margin: 0;
        margin-bottom: 8px;
        display: flex;
        .serie-sum {
          font-size: 16px;
          border: none;
          margin: 0;
          margin-left: auto;
        }
      }

      .serie-sum {
        margin-top: 8px;
        border-top: 1px solid #ccc;
        width: 100%;
        text-align: end;
        font-weight: 500;

        span + span {
          margin-left: 20px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    min-width: auto;

    &.wide {
      min-width: auto;
    }

    .felt-serie {
      flex-basis: 100%;
      padding: 0;

      .felt-serie_image {
        width: 100%;
        margin: 0 auto 20px;
      }

      .felt-serie_wrap {
        flex-direction: column;
      }
    }
    .unified-serie {
      flex-basis: 100%;
      padding: 0;

      .unified-serie_image {
        width: 100%;
        margin: 0 auto 20px;
      }

      .unified-serie_wrap {
        flex-direction: column;
      }
    }

    .bane-serie {
      flex-direction: column;
      padding: 0;
      align-items: initial;

      .bane-serie_image {
        width: 100%;
        margin: 0 auto 20px;
      }
    }
  }
}

.detailed-table {
  .cell {
    &--is-shots {
      flex-flow: column wrap;
      align-items: flex-end;
      justify-content: center;
      padding-right: 17px;

      .shots-block {
        font-family: $roboto-mono;
        font-weight: 300;
        font-size: 12px;
        color: $text-color;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }
}

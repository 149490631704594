@import './variables';

html {
  box-sizing: border-box;
  height: 100%;
  line-height: 1.4;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  background-color: $body-color;
  -webkit-overflow-scrolling: touch;

  &.ReactModal__Body--open {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
}

.overflowHidden {
  overflow: hidden;
}

#root {
  flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a {
  color: $main-color;

  &:hover {
    text-decoration: none;
  }
}

p {
  font-size: 18px;
}

img {
  max-width: 100%;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
}

.hidden-up-to-767 {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.hidden-up-to-1199 {
  @media (max-width: 1199px) {
    display: none !important;
  }
}

.hidden-up-to-1439 {
  @media (max-width: 1439px) {
    display: none !important;
  }
}

.hidden-from-768 {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.hidden-from-1200 {
  @media (min-width: 1200px) {
    display: none !important;
  }
}

.hidden-from-1440 {
  @media (min-width: 1440px) {
    display: none !important;
  }
}

.bootstrap-styles {
  .btn {
    height: auto;
  }

  @import '~bootstrap/scss/bootstrap';

  min-height: 100vh;
  height: auto;
  display: flex;
  align-items: flex-start;

  &--is-abs {
    position: absolute;
  }

  &--is-small {
    min-height: 0;
  }
}

$spinkit-size: 4em !default;
$spinkit-spinner-color: #337ab7 !default;

.js-loaded {
  display: flex;
  flex: 1 1 100%;
  height: 300px;

  .searchpage & {
    height: calc(100vh - 200px);
  }

  &--is-small {
    margin-bottom: 16px;
    height: 38px;

    .sk-spinner-pulse {
      width: 38px;
      height: 38px;
    }
  }
}

.sk-spinner-pulse {
  width: $spinkit-size;
  height: $spinkit-size;
  margin: auto;
  background-color: $spinkit-spinner-color;
  border-radius: 100%;
  animation: sk-spinner-pulse 1s infinite ease-in-out;
}

@keyframes sk-spinner-pulse {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

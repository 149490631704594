%admin-button {
  cursor: pointer;
  border: 1px solid $main-color;
  border-radius: 5px;
  background: $main-color;
  font-family: $main-font;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.167;
  text-transform: uppercase;
  color: $white;
  transition: 300ms all ease;
  padding: 7px 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba($main-color, 0.9);
  }
}

%admin-grey-hover-button {
  transition: 300ms all ease;

  &:hover {
    background: rgba($ad-grey-1, 0.07);
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background: rgba($ad-grey-1, 0.1);
  }
}

%admin-round-button {
  @extend %admin-grey-hover-button;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  border-radius: 50%;
}

@import 'notifications';
@import 'breadcrumbs';
@import 'form-elements';
@import 'layout';
@import 'admin-table';
@import 'menu-edit-page';
@import 'reports-page';
@import 'user-info';

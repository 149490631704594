@import 'variables';

.clubtablepage {
  .full-page-wrapper {
    margin: 50px auto;
  }

  .error-wrapper {
    margin-top: 100px;
    text-align: center;

    p {
      font-size: 24px;
    }
  }
}

.clubshooter-search {
  &__input {
    margin: 10px 0 $main-padding;
  }
}

.clubshooter-table {
  &__wrapper {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  width: 100%;
  border-collapse: collapse;
  border: 1px solid $separator;
  text-align: center;

  th,
  td {
    padding: 10px 12px;

    &:nth-child(2) {
      text-align: left;
    }
  }

  thead {
    tr {
      border-bottom: 1px solid $separator;

      &:first-child {
        text-align: left;
      }

      &:nth-child(2) {
        th {
          background-color: #f4f4f4;
          border: 1px solid $separator;

          &:after {
            content: '↕';
            position: absolute;
            right: 3px;
            top: 50%;
            transform: translateY(-50%);
            // margin-top: -2px;
            opacity: 0.4;
          }

          &:hover {
            cursor: pointer;
            background-color: $separator;
          }

          &.is-asc,
          &.is-desc {
            &:after {
              opacity: 1;
              color: #000;
              font-size: 19px;
              // font-weight: 700
            }
          }

          &.is-asc {
            &:after {
              content: '↑';
            }
          }

          &.is-desc {
            &:after {
              content: '↓';
            }
          }
        }
      }
    }

    th {
      position: relative;

      &.category-th {
        border-left: 1px solid $separator;
      }
    }
  }

  td {
    border: 1px solid $separator;
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background-color: $back-grey;
      }

      &:hover {
        outline: 1px dashed $dim-color;
      }
    }
  }
}

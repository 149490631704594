@import 'variables';

.sidebar-search {
  $margin: 10;

  width: calc(100% - #{$margin * 2}px);
  margin: 26px #{$margin}px;

  &__label {
    display: flex;
    text-transform: uppercase;
    font-family: $main-font;
    font-weight: 700;
    font-size: 10px;
    color: $light-dim-color;
    letter-spacing: 0.75px;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  &__shadow-wrapper {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 3;
  }

  &__input {
    position: relative;
    z-index: 2;
    border: 1px solid $input-border;
    border-radius: 5px;
    width: 100%;
    font-family: $main-font;
    font-weight: 300;
    font-size: 16px;
    color: $text-color;
    letter-spacing: 0;
    padding: 10px 18px;
    outline: none;
    -webkit-appearance: none;

    &--is-opened {
      border-bottom: none;
      border-color: $main-color;
      margin-bottom: 1px;
      border-radius: 5px 5px 0 0;
    }
  }
}

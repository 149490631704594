@import "variables";
@import "mixins";

.site-aside-navigation {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 300px;
  height: height-vh(100);
  background-color: #fff;
  box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  will-change: transform, opacity;
  display: flex;
  flex-flow: column;
}

.site {
  &-navigation {

    &-enter,
    &-appear {
      opacity: 0;
      transform: translateX(-300px);
    }

    &-enter-active,
    &-appear-active {
      transform: translateX(0);
      opacity: 1;
      transition: all 0.3s;
    }

    &-exit {
      transform: translateX(0);
      opacity: 1;
      transition: all 0.3s;
    }

    &-exit-active {
      opacity: 0;
      transform: translateX(-300px)
    }
  }
}

.nav {
  &-wrapper {
    display: flex;
    flex-flow: column;
    flex: auto 1 1;
    min-height: 0;
  }

  &__close-block {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid $separator;
  }

  &__close-layer {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    height: height-vh(100);
    width: 100vw;
  }

  &__close {
    &-wrap {
      flex: none;
      margin-left: $main-padding;

      @media (max-width: 767px) {
        margin-left: 15px;
      }
    }
  }

  &__items {
    padding: 0;
    margin-top: 0;
    flex: auto 1 1;
    min-height: 0;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
  }

  &__item {
    display: block;
    padding: 0;
    width: 100%;
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 30px;
    text-decoration: none;

    &.current {
      border-left: 5px solid $main-color;
      background-color: #eee;
    }

    &:hover {
      background-color: #eee;
    }
  }

  &-years {
    margin: 20px $main-padding 10px;
    flex-shrink: 0;

    @media screen {
      margin: 20px 15px 10px;
    }
  }

  &-year {
    color: $cool-grey;
    background-color: $border-grey;
    margin-bottom: 10px;
    transition: 250ms all ease;
    user-select: none;

    &:hover {
      color: #fff;
    }

    &.active-year {
      color: #fff;
      background-color: $main-color;
    }
  }
}

@import './variables';

.results-list {
  display: flex;
  flex-flow: column wrap;
  padding: 0;
  margin: 0;
  list-style: none;

  &__wrapper {
    max-height: 360px;
    overflow-y: scroll;
    border: 1px solid $main-color;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 1;
    background-color: #fff;

    &--is-sidebar-search {
      top: calc(100% - 1px);
    }

    &--is-sidebar-select {
      top: calc(100% - 1px);
    }
  }

  &__variant {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 5px 0 5px 20px;

    transition: all 0.2s;
    cursor: pointer;
    font-family: $main-font;
    font-weight: 500;
    font-size: 16px;
    color: $text-color;
    letter-spacing: 0;

    &--is-selected {
      background: rgba($radio-color, 0.5);
    }

    &:hover {
      background: $radio-color;
    }
  }

  &__label {
    padding: 4px 7px 2px;
    display: flex;
    align-items: center;
    position: relative;
    text-transform: uppercase;
    font-family: $main-font;
    font-weight: 500;
    font-size: 11px;
    color: $cool-grey;
    letter-spacing: 1px;
    line-height: 12px;
    border-radius: 4px;
    overflow: hidden;
    margin-left: 8px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba($text-color, 0.5);
      opacity: 0.15;
    }
  }
}

@import 'variables';

.modal {
  position: relative;
  z-index: 1;
  width: 600px;
  max-height: calc(100% - 130px);
  padding: 35px 40px 40px 40px;
  overflow: auto;
  background: #fff;

  &--is-shooter {
    width: 710px;
  }

  @media (max-width: 767px) {
    padding: 15px 20px 20px 20px;
  }

  &-wrapper {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__close {
    appearance: none;
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    @media (max-width: 767px) {
      top: 10px;
      right: 10px;
    }

    &:hover {
      svg {
        fill: $main-color;
      }
    }

    svg {
      fill: $text-color;
      width: 30px;
      height: 30px;
    }

    &--is-alt {
      height: 20px;
      width: 20px;
      right: 18px;
      top: 23px;

      &:after {
        content: '×';
        font-family: $main-font;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $ad-dark-blue-2;
      }

      svg {
        display: none;
      }
    }
  }
}

.modal-component {
  position: absolute;
  left: 50%;
  top: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 130px);
  min-width: 600px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 64px 30px 30px 30px;
  display: flex;
  flex-flow: column;

  &--is-menu-edit {
    min-width: 680px;

    .modal__close {
      &:before {
        content: '';
        top: -3px;
        left: -4px;
        right: -4px;
        bottom: -6px;
        position: absolute;
        transition: 300ms all ease;
        border-radius: 50%;
      }

      &:hover {
        cursor: pointer;

        &:before {
          background: rgba($ad-grey-1, 0.07);
        }
      }

      &:focus {
        outline: none;

        &:before {
          background: rgba($ad-grey-1, 0.1);
        }
      }
    }
  }

  @media (max-width: 767px) {
    min-width: 290px;
  }

  &__title {
    font-family: $main-font;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $ad-text-color;
    margin: 0;
    position: absolute;
    top: 22px;
    left: 29px;
  }

  &__overlay {
    position: fixed;
    z-index: 40;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($ad-dark-blue, 0.8);
  }
}

.notifications-provider {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.notification {
  &-enter,
  &-appear {
    //opacity: 0;
    transform: translateX(calc(100% + 20px));
    transition: all 0.3s;
  }

  &-enter-active,
  &-enter-done,
  &-appear-active {
    //opacity: 1;
    transform: translateX(0);
    transition: all 0.3s;
  }

  &-exit {
    //opacity: 1;
    transform: translateX(0);
    transition: all 0.2s;
  }

  &-exit-active {
    //opacity: 0;
    transform: translateX(calc(100% + 20px));
    transition: all 0.2s;
  }
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: local('Roboto Italic'), local('Roboto-Italic'), url(../fonts/Roboto-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: fallback;
  src: local('Roboto Light'), local('Roboto-Light'), url(../fonts/Roboto-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Roboto'), local('Roboto-Regular'), url(../fonts/Roboto-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(../fonts/Roboto-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(../fonts/Roboto-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono Medium'), local('RobotoMono-Medium'),
  url('../fonts/RobotoMono-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
  url('../fonts/RobotoMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono Bold'), local('RobotoMono-Bold'),
  url('../fonts/RobotoMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono Light'), local('RobotoMono-Light'),
  url('../fonts/RobotoMono-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

.layout {
  $button-side: 30px;

  display: flex;
  height: 100vh;
  position: relative;

  &__sidebar {
    flex-basis: 240px;
    background: $ad-dark-blue;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.08);
    position: relative;

    @media (max-width: 1023px) {
      transition: 300ms all ease;
      width: 240px;
      max-width: calc(100vw - #{$button-side});
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      z-index: 2;

      &--is-opened {
        transform: translateX(100%);
      }
    }
  }

  &__sidebar-button {
    position: absolute;
    left: 100%;
    height: $button-side;
    width: $button-side;
    background: $ad-dark-blue;
    border: none;
    top: 20px;
    padding: 7px;
    color: $white;
    cursor: pointer;

    svg {
      height: 100%;
      width: 100%;
      fill: currentColor;
    }

    &:focus {
      outline: none;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__logo-wrapper {
    display: flex;
    flex-flow: column;
    padding: 20px 24px 0 20px;

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background: $ad-dark-grey;
      margin-top: 19px;
    }
  }

  &__logo-link {
    display: flex;
  }

  &__logo {
    width: 100%;
    height: 100%;

    path {
      fill: $white !important;
    }
  }

  &__sidebar-links {
    display: flex;
    flex-flow: column;
    padding-left: 0;
    margin-top: 9px;
    margin-bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__sidebar-link-item {
    display: flex;
    text-decoration: none;
    font-family: $main-font;
    font-size: 13.3px;
    line-height: 16px;
    color: $ad-grey;
    padding: 14px 15px 13px 24px;
    width: 100%;

    &--is-active {
      background: $main-color;
      color: $ad-light-grey;
    }
  }

  &__sidebar-link-icon-wrapper {
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  &__sidebar-link-icon {
    transform: translateY(-1px);
  }

  &__sidebar-link-wrapper {
    list-style: none;
    display: flex;
  }

  &__top-bar {
    padding: 20px 16px 20px 20px;
    display: flex;
    background: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }

  &__years {
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
    margin: 0;
  }

  &__year {
    list-style: none;
    font-family: $main-font;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 10px;
    min-width: 60px;

    &--is-active {
      padding: 8px 16px;
      color: $white;
      background: $main-color;
      font-weight: bold;
    }
  }

  &__year-button {
    border-radius: 5px;
    background: $border-grey;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $cool-grey;
    padding: 8px 16px;
    width: 100%;
    text-decoration: none;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background: darken($border-grey, 10%);
    }
  }
}

.layout-top-bar-user {
  margin-left: auto;
  display: flex;
  align-items: center;
  min-height: 30px;
  position: relative;
  min-width: 100px;
  justify-content: flex-end;

  &__button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: $ad-grey-3;
    font-family: $helvetica;
    font-size: 14.4px;
    letter-spacing: 0;
    line-height: 17px;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    margin-left: 11px;
    height: 5px;
  }

  &__dropdown {
    display: flex;
    flex-flow: column;
    position: absolute;
    right: 0;
    top: 100%;
    background: $white;
    margin: 0;
    padding: 5px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0784314);
    border-radius: 4px;
  }

  &__dropdown-item {
    display: flex;
  }

  &__dropdown-item-button {
    @extend %admin-grey-hover-button;

    width: 100%;
    border: none;
    background: none;
    color: $ad-grey-3;
    font-family: $helvetica;
    font-size: 14.4px;
    letter-spacing: 0;
    line-height: 1.1805;
    padding: 8px 18px;
  }
}

.layout {
  &__content {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
  }

  &__paper {
    background: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0784314);
    border-radius: 4px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
  }

  &__scroll-content {
    overflow-y: auto;
    flex-grow: 1;
  }

  &__page-info {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 20px 20px 25px 25px;
  }

  &__page-title {
    margin-right: auto;
    color: $ad-text-color;
    font-family: $main-font;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-transform: uppercase;
    margin-top: 4px;
    margin-bottom: 0;
  }
}

// Colors
$body-color: #fbfaf9;
$separator: #ddd;
$back-grey: #eff0f1;
$border-grey: #f4f4f4;
$main-color: #337ab7;
$dim-color: #658ca9;
$light-dim-color: #8798AD;
$cool-grey: #69707f;
$cooler-grey: #bfc5d2;
$text-color: #2e384d;
$input-border: #ccc;
$radio-color: #eee;
$block-color: #979797;
$white: #fff;

$logo-red: #d56164;

// admin colors
$ad-dark-blue: #252B3B;
$ad-dark-blue-2: #265585;
$ad-blue: #4aa4ff;
$ad-green: #1cbb8c;
$ad-dark-grey: #323642;
$ad-light-grey: #D7E4EC;
$ad-grey: #8590A5;
$ad-text-color: #343A40;
$ad-grey-1: #505d69;
$ad-grey-2: #74788D;
$ad-grey-3: #636E75;
$ad-light-grey-2: #f8f9fa;
$ad-light-grey-3: #CED4DA;
$red: #FF3D3D;

// Fonts
$main-font: "Roboto", sans-serif;
$roboto-mono: "Roboto Mono", sans-serif;
$helvetica: "Roboto", sans-serif;

// Sizes
$main-padding: 30px;
$wrapper-width: 780px;
$wrapper-padding: 10px;
$avatar-image: 34px;

@import '../styles/variables.scss';
@import 'mixins';

body.searchpage {
  padding-top: 200px;

  @media (max-width: 767px) {
    padding-top: 140px;
  }
}

.searchpage .site-header__search {
  > button {
    display: none;
  }
}

.search-results {
  &__wrapper {
    margin-top: 40px;
  }

  &__th {
    display: flex;
    margin: 15px 0;
    font-weight: 700;
    font-size: 12px;
    color: $dim-color;
    text-transform: uppercase;
    letter-spacing: 1.12px;
  }
}

.search-result {
  &__shooter {
    @extend %table-hover;

    z-index: 1;
    display: flex;
    align-items: center;
    height: 60px;
    margin-bottom: 5px;
    border: 1px solid $border-grey;
    border-radius: 1px;
    background-color: #fff;
    transition: all 0.2s;

    .badge {
      margin: 1px 0 1px 5px;

      @media (max-width: 767px) {
        margin-left: 3px;
      }

      @media (max-width: 480px) {
        margin-left: 1px;
      }

      &__accent {
        margin: 1px 0 1px 8px;

        @media (max-width: 767px) {
          margin-left: 1px;
        }
      }
    }
  }

  &__avatar {
    flex: none;
    display: block;
    width: $avatar-image;
    height: $avatar-image;
    line-height: $avatar-image;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50%;
    overflow: hidden;
    background-color: $back-grey;

    @media (max-width: 767px) {
      display: none;
    }

    img {
      display: inline-block;
      vertical-align: baseline;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.sr__col {
  &-1,
  &-2,
  &-3,
  &-4 {
    position: relative;
    z-index: 1;
  }

  &-0 {
    display: flex;
    align-items: center;
    width: 63%;

    @media (max-width: 767px) {
      position: relative;
      flex-wrap: wrap;
      width: 66%;
    }
  }

  &-1 {
    width: 37.5%;
    padding-left: 65px;

    @media (max-width: 767px) {
      position: static;
      width: 66%;
      padding-left: 10px;
    }

    .search-result__shooter & {
      width: 59%;
      display: flex;
      align-items: center;
      padding-left: 20px;
      font-weight: 500;

      @media (max-width: 767px) {
        width: 100%;
        padding-left: 10px;
      }

      @media (max-width: 480px) {
        font-size: 13px;
        margin-bottom: 3px;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 2;
        display: block;
        width: 1px;
        height: 30px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: $back-grey;
      }
    }
  }

  &-2 {
    width: 26%;
    padding-left: 10px;

    @media (max-width: 767px) {
      display: none;
    }

    .search-result__shooter & {
      width: 41%;
      padding: 0 10px;
      font-size: 16px;
      color: $dim-color;

      @media (max-width: 767px) {
        display: block;
        width: 100%;
      }

      @media (max-width: 480px) {
        margin-top: 3px;
        font-size: 12px;
      }
    }
  }

  &-3 {
    width: 20%;
    text-align: right;
    padding-right: 10px;

    @media (max-width: 767px) {
      width: 34%;
    }

    .search-result__shooter & {
      &:after {
        content: '';
        position: absolute;
        z-index: 2;
        display: block;
        width: 1px;
        height: 30px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: $back-grey;
      }
    }
  }

  &-4 {
    width: 18%;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

@import './variables';

.input {
    appearance: none;
    padding: 10px;
    font-family: $main-font;
    color: $text-color;
    border-radius: 5px;
    border: 1px solid $input-border;
    outline: none;
    transition: all 0.4s;

    &:focus {
        border-color: $main-color;
    }
}
@import 'variables';

.sidebar-select {
  $margin: 30;

  width: calc(100% - #{$margin * 2}px);
  margin: 0 #{$margin}px;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  &__label {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    border: 1px solid $input-border;
    padding: 10px 14px 12px 10px;

    font-family: $main-font;
    font-size: 12px;
    color: $text-color;
    letter-spacing: 0;
    cursor: pointer;
    user-select: none;

    &--is-opened {
      border-bottom: none;
      border-color: $main-color;
      margin-bottom: 1px;
      border-radius: 5px 5px 0 0;
    }
  }

  &__label-icon {
    transform: translateY(1px);
  }

  &__labels {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    width: calc(100% - #{$margin * 2}px);
    margin: 11px #{$margin}px;
  }
}

.detailed-table {
  .cell--is-total-star {
    padding: 0;
    justify-content: flex-end;

    @media (max-width: 767px) {
      min-width: 100px;
      flex-flow: column;
      justify-content: center;

      .star-block {
        font-size: 10px;
        color: $text-color;
        line-height: 0.6;
        font-weight: 100;

        &:after {
          display: none;
        }

        &:before {
          font-size: 12px;
          top: 2px;
        }
      }

      .score-block {
        line-height: 1;
      }

      .cell {
        &--is-total {
          margin-bottom: 4px;
          justify-content: center;
          padding: 0;
        }
      }
    }

    .cell--is-aligned-start {
      justify-content: flex-start;
      @media (max-width: 767px) {
        justify-content: center;
      }
    }

    &.cell--is--aligned-end {
      @media (max-width: 767px) {
        align-items: flex-end;
        padding-right: 10px;

        .cell {
          &--is-total {
            padding: 0 25px 0 10px;
            justify-content: flex-end;
          }

          &--is-star {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.detailed-table {
  .score-block {
    font-family: $roboto-mono;
    font-weight: 400;
    font-size: 20px;
    color: $text-color;
    letter-spacing: 0;
    text-align: right;
    line-height: 1.1;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 22px;
    }

    &--is-total {
      font-weight: 600;
    }

    &--is-shots {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      font-weight: 600;
    }

    &--is-big {
      font-size: 20px;
    }
  }

  .star-block {
    font-family: $main-font;
    font-style: italic;
    font-size: 14px;
    color: $cool-grey;
    letter-spacing: 0;
    line-height: 1;
    position: relative;
    width: 45px;
    padding: 2px 0 2px 20px;

    &:before {
      content: '*';
      position: absolute;
      left: 9px;
      top: 4px;
      font-size: 18px;
      font-style: normal;
    }

    &:after {
      content: '';
      position: absolute;
      background: rgba($text-color, 0.5);
      opacity: 0.15;
      border-radius: 4px 0 0 4px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

@import 'variables';

.btn {
  appearance: none;
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  font-weight: 500;
  font-size: 13px;
  font-family: $main-font;
  color: #fff;
  border: none;
  border-radius: 4px;
  background-color: $main-color;
  transition: background-color 0.25s ease-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: $dim-color;
  }

  &-big {
    display: block;
    height: 50px;
  }

  &--is-secondary-row {
    height: 40px;
    text-transform: uppercase;
    font-family: $main-font;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px;
  }

  &--default {
    z-index: 1;
  }

  &--has-top-offset {
    margin-top: 10px;
  }

  &--is-full-width {
    width: 100%;
  }

  &--is-loading {
    cursor: not-allowed;
    background-color: $dim-color;
  }
}

.badge {
  appearance: none;
  display: inline-block;
  vertical-align: top;
  min-width: 28px;
  padding: 0 3px;
  height: 26px;
  line-height: 26px;
  font-weight: 700;
  font-size: 10px;
  font-family: $main-font;
  color: $cool-grey;
  text-align: center;
  border-radius: 4px;
  background-color: $back-grey;


  &.bold-badge {
    font-weight: bold;
    min-width: 40px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    color: $dim-color;
  }

  &__accent {
    color: $text-color;
    font-size: 11px;
  }
}

.header-btn {
  appearance: none;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
  font-family: $main-font;
  color: $main-color;
  text-transform: uppercase;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;

  &:hover,
  &:active {
    color: $dim-color;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    font-size: 13px;

    svg {
      margin-bottom: 5px;
    }

  }

  svg {
    fill: currentColor;
  }

  &__menu {
    @media (max-width: 767px) {
      align-items: flex-start;
    }

    svg {
      width: 20px;
      height: 18px;
      margin-right: 30px;

      @media (max-width: 767px) {
        order: 0;
        margin-right: 0;
      }
    }

    span {
      @media (max-width: 767px) {
        order: 1;
      }
    }
  }

  &__search {
    @media (max-width: 767px) {
      align-items: flex-end;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-left: 30px;

      @media (max-width: 767px) {
        order: 0;
        margin-left: 0;
      }
    }

    span {
      @media (max-width: 767px) {
        order: 1;
      }
    }
  }
}
